<template>
  <div class="cell">
    <md-card class="md-card-flat">
      <md-card-expand>
        <md-card-actions md-alignment="left">
          <md-card-expand-trigger>
            <md-button class="md-icon-button">
              <md-icon>keyboard_arrow_down</md-icon>
              <md-tooltip>Zeige Simulations-Parameter</md-tooltip>
            </md-button>
          </md-card-expand-trigger>
          <div>
            <label style="font-size: large">&nbsp;&nbsp; Simulations-Parameter <md-icon style="color: green; margin-bottom: 6px;">settings</md-icon></label>
            <md-tooltip md-direction="bottom">Was-wäre-wenn? Simulation von zusätzlichen Kindern oder Mitarbeitern</md-tooltip>
          </div>
        </md-card-actions>

        <md-card-expand-content>
          <md-card-content style="padding-bottom: 8px; padding-top: 0;">

            <md-switch class="md-primary" v-model="simulationActive" @change="toggleSimulation">
              <span v-if="simulationActive" style="font-size: large;"><b class="flash-orange-red"><i>Simulation aktiv</i></b></span>
              <span v-else style="font-size: large; color: gray">Simulation</span>
              <md-tooltip>
                <span v-if="!simulationActive">Simulation aktivieren</span>
                <span v-else>Ergebnis wird im Dashboard angezeigt</span>
              </md-tooltip>
            </md-switch>

            <md-table v-model="simChildren" md-sort="number" md-sort-order="desc"
                      md-card md-fixed-header @md-selected="onSelectSimChildrenEntry" style="height: 299px">
              <md-table-toolbar>
                <div class="md-toolbar-section-start grid-x grid-padding-x">
                  <div class="cell small-6" style="margin-top: 16px;margin-bottom: 16px;">
                    <p style="color: seagreen;">Zusätzliche Kinder</p>
                    <p class="md-title ai-number">{{numberOfSimChildren}} Kind<span v-if="numberOfSimChildren !== 1">er</span></p>
                  </div>
                  <div class="small-6">
                    <md-button @click="onAddSimData('children')"
                               class="md-icon-button md-raised md-primary">
                      <md-icon>group_add</md-icon>
                      <md-tooltip>Kinder hinzufügen</md-tooltip>
                    </md-button>
                    <md-button @click="onEditSimData('children')"
                               :disabled="!selectedSimChildrenEntry"
                               class="md-icon-button md-raised md-third">
                      <md-icon>create</md-icon>
                      <md-tooltip>Eintrag bearbeiten</md-tooltip>
                    </md-button>
                    <md-button @click="onRemoveSimData('children')"
                               :disabled="!selectedSimChildrenEntry"
                               class="md-icon-button md-raised md-accent">
                      <md-icon>delete</md-icon>
                      <md-tooltip>Eintrag löschen</md-tooltip>
                    </md-button>
                  </div>
                </div>
              </md-table-toolbar>

              <md-table-row style="padding: 0;" slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single">
                <md-table-cell style="padding: 0;" md-label="Anz" md-sort-by="number">{{ item.number }}
                </md-table-cell>
                <md-table-cell style="padding: 0;" md-label="von" md-sort-by="startMonth">{{$material.locale.shortMonths[item.startMonth - 1]}}
                </md-table-cell>
                <md-table-cell style="padding: 0;" md-label="bis" md-sort-by="endMonth">{{$material.locale.shortMonths[item.endMonth - 1]}}
                </md-table-cell>
                <md-table-cell style="padding: 0;" md-label="Wochen-h" md-sort-by="hoursPerWeek">{{item.hoursPerWeek}}
                </md-table-cell>

                <md-tooltip>
                  <b>{{item.number}}</b> Kind<span v-if="item.number !== 1">er</span><br>von <b>{{$material.locale.months[item.startMonth - 1]}}</b>&nbsp;bis <b>{{$material.locale.months[item.endMonth - 1]}}</b><br><b>{{item.hoursPerWeek}}</b> Wochenstunde<span v-if="item.hoursPerWeek !== 1">n</span><br>Förderungsmerkmal <b>{{labels.supportFactor[item.supportFactor]}}</b>
                </md-tooltip>

              </md-table-row>
            </md-table>

            <br>

            <md-table v-model="simEmployees" md-sort="number" md-sort-order="desc"
                      md-card md-fixed-header @md-selected="onSelectSimEmployeesEntry" style="height: 299px">
              <md-table-toolbar>
                <div class="md-toolbar-section-start grid-x grid-padding-x">
                  <div class="cell small-6" style="margin-top: 16px;margin-bottom: 16px;">
                    <p style="color: seagreen;">Zusätzliche Mitarbeiter</p>
                    <p class="md-title ai-number">{{numberOfSimEmployees}} Mitarbeiter</p>
                  </div>
                  <div class="small-6">
                    <md-button @click="onAddSimData('employees')"
                               class="md-icon-button md-raised md-primary">
                      <md-icon>group_add</md-icon>
                      <md-tooltip>Mitarbeiter hinzufügen</md-tooltip>
                    </md-button>
                    <md-button @click="onEditSimData('employees')"
                               :disabled="!selectedSimEmployeeEntry"
                               class="md-icon-button md-raised md-third">
                      <md-icon>create</md-icon>
                      <md-tooltip>Eintrag bearbeiten</md-tooltip>
                    </md-button>
                    <md-button @click="onRemoveSimData('employees')"
                               :disabled="!selectedSimEmployeeEntry"
                               class="md-icon-button md-raised md-accent">
                      <md-icon>delete</md-icon>
                      <md-tooltip>Eintrag löschen</md-tooltip>
                    </md-button>
                  </div>
                </div>
              </md-table-toolbar>

              <md-table-row style="padding: 0;" slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single">
                <md-table-cell style="padding: 0;" md-label="Anz" md-sort-by="number">{{ item.number }}
                </md-table-cell>
                <md-table-cell style="padding: 0;" md-label="von" md-sort-by="startMonth">{{$material.locale.shortMonths[item.startMonth - 1]}}
                </md-table-cell>
                <md-table-cell style="padding: 0;" md-label="bis" md-sort-by="endMonth">{{$material.locale.shortMonths[item.endMonth - 1]}}
                </md-table-cell>
                <md-table-cell style="padding: 0;" md-label="Wochen-h" md-sort-by="hoursPerWeek">{{item.hoursPerWeek}}
                </md-table-cell>

                <md-tooltip>
                  <b>{{item.number}}</b> Mitarbeiter<br>von <b>{{$material.locale.months[item.startMonth - 1]}}</b>&nbsp;bis <b>{{$material.locale.months[item.endMonth - 1]}}</b><br><b>{{item.hoursPerWeek}}</b> Wochenstunde<span v-if="item.hoursPerWeek !== 1">n</span><br>Fachkraft <b>{{booleanText(item.fachkraft)}}</b>
                </md-tooltip>

              </md-table-row>
            </md-table>

          </md-card-content>
        </md-card-expand-content>
      </md-card-expand>
    </md-card>

    <EditSimulationDataDialog :simulationDataEntry="simulationDataEntry" :showDialog="showEditSimulationEntryDialog"
                              :mode="simulationEntryEditMode" :type="simulationEntryType"
                              @closeEditSimulationDataDialog="showEditSimulationEntryDialog = false"
                              @updateSimulationDataEntry="onUpdateSimulationDataEntry"
                              @deleteSimulationDataEntry="onDeleteSimulationDataEntry"
                              @addSimulationDataEntry="onAddSimulationDataEntry"/>

  </div>
</template>

<script>
  import Vue from "vue";
  import FacilityService from "../../services/FacilityService";
  import HttpErrorHandler from "../../services/HttpErrorHandler";

  const EditSimulationDataDialog = () => import(/* webpackChunkName: "group-dashboard" */ '../dialogs/EditSimulationDataDialog');

  export default {
    name: 'SimulationCard',

    components: {
      EditSimulationDataDialog,
    },

    props: {
      selectedFacilityReferenceNumber: {
        required: false
      },
      selectedYear: {
        required: false
      },
      simActive: {
        required: false
      },
    },

    watch: {
      simActive() {
        this.simulationActive = this.simActive;
        this.restoreSimulationData();
      },
    },

    mounted() {
      this.restoreSimulationData();
    },

    data() {
      return {
        simulationDataEntry: {},
        showEditSimulationEntryDialog: false,
        simulationEntryEditMode: 'add',
        simulationEntryType: 'children',

        simulationActive: false,
        simChildren: [],
        selectedSimChildrenEntry: null,

        simEmployees: [],
        selectedSimEmployeeEntry: null,
      }
    },

    methods: {

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      onAddSimData(type) {
        this.simulationEntryEditMode = 'add';
        this.simulationEntryType = type;

        if (type === 'children') {
          this.simulationDataEntry = {
            id: -1,
            number: '',
            startMonth: Vue.moment().format('M'),
            endMonth: Vue.moment().format('M'),
            hoursPerWeek: '',
            keepChildsConstant: false,
            supportFactor: 'REGULAR',
          };
        }
        if (type === 'employees') {
          this.simulationDataEntry = {
            id: -1,
            number: '',
            startMonth: Vue.moment().format('M'),
            endMonth: Vue.moment().format('M'),
            hoursPerWeek: '',
            fachkraft: true,
          };
        }

        this.showEditSimulationEntryDialog = true;
      },

      onEditSimData(type) {
        this.simulationEntryEditMode = 'update';
        this.simulationEntryType = type;
        if (type === 'children') {
          this.simulationDataEntry = this.selectedSimChildrenEntry;
        }
        if (type === 'employees') {
          this.simulationDataEntry = this.selectedSimEmployeeEntry;
        }
        this.showEditSimulationEntryDialog = true;
      },

      onRemoveSimData(type) {
        this.simulationEntryEditMode = 'delete';
        this.simulationEntryType = type;
        if (type === 'children') {
          this.simulationDataEntry = this.selectedSimChildrenEntry;
        }
        if (type === 'employees') {
          this.simulationDataEntry = this.selectedSimEmployeeEntry;
        }
        this.showEditSimulationEntryDialog = true;
      },

      onSelectSimChildrenEntry(item) {
        this.selectedSimChildrenEntry = item;
      },

      onSelectSimEmployeesEntry(item) {
        this.selectedSimEmployeeEntry = item;
      },

      onUpdateSimulationDataEntry() {
        this.updateSimulation();
      },

      onDeleteSimulationDataEntry(entry) {
        let updatedArray = [];
        if (this.simulationEntryType === 'children') {
          this.simChildren.forEach(item => {
            if (entry.id !== item.id) {
              updatedArray.push(item);
            }
          })
          this.simChildren = updatedArray;
        }
        else if (this.simulationEntryType === 'employees') {
          this.simEmployees.forEach(item => {
            if (entry.id !== item.id) {
              updatedArray.push(item);
            }
          })
          this.simEmployees = updatedArray;
        }
        this.updateSimulation();
      },

      onAddSimulationDataEntry(entry) {
        if (this.simulationEntryType === 'children') {
          entry.id = this.simChildren.length;
          this.simChildren.push(entry);
        }
        else if (this.simulationEntryType === 'employees') {
          entry.id = this.simEmployees.length;
          this.simEmployees.push(entry);
        }
        this.updateSimulation();
      },

      storeSimulationData() {
        localStorage.setItem(this.user.md5 + '@simChildren',JSON.stringify(this.simChildren));
        localStorage.setItem(this.user.md5 + '@simEmployees',JSON.stringify(this.simEmployees));
        this.$emit("updateSimulationData");
      },

      restoreSimulationData() {
        if (localStorage.getItem(this.user.md5 + '@simChildren')) {
          this.simChildren = JSON.parse(localStorage.getItem(this.user.md5 + '@simChildren'));
        }
        if (localStorage.getItem(this.user.md5 + '@simEmployees')) {
          this.simEmployees = JSON.parse(localStorage.getItem(this.user.md5 + '@simEmployees'));
        }
      },

      toggleSimulation() {
        this.updateSimulation();
      },

      updateSimulation() {
        this.storeSimulationData();

        this.$emit("toggleSimulation", this.simulationActive);

        if (this.simulationActive) {
          let simChildren = [];
          this.simChildren.forEach(entry => {
            for (let i = 0; i < entry.number; i ++) {
              simChildren.push(entry)
            }
          });
          let simEmployees = [];
          this.simEmployees.forEach(entry => {
            for (let i = 0; i < entry.number; i ++) {
              simEmployees.push(entry)
            }
          });
          let simulationData = {
            simChilds: simChildren,
            simEmployees: simEmployees,
          };
          this.simulateKibigData(this.selectedFacilityReferenceNumber, this.selectedYear, simulationData);

        } else {
          this.$store.commit('successMsg', 'Simulation ist deaktiviert');
        }
      },

      simulateKibigData(facilityReferenceNo, year, simulationData) {
        if (!this.facilitiesNotExists) {
          FacilityService.simulateKibigData(facilityReferenceNo, year, simulationData)
            .then(response => {
              this.$emit("simulateStatistic", response.data);
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Simulieren der KiBiG-Daten der Einrichtung '
                + this.selectedFacilityName + ' für das Jahr ' + year);
            })
        }
      },

      booleanText(value) {
        return value ? 'ja' : 'nein';
      },
    },

    computed: {

      numberOfSimChildren() {
        let total = 0;
        this.simChildren.forEach((entry) => total += Number(entry.number));
        return total;
      },

      numberOfSimEmployees() {
        let total = 0;
        this.simEmployees.forEach((entry) => total += Number(entry.number));
        return total;
      },

      user() {
        return this.$store.getters.user;
      },

      labels() {
        return this.$store.getters.labels
      },
    },
  }
</script>

<style lang="scss" scoped>
  .md-tooltip {
    font-size: medium;
    height: auto;
    max-width: 330px;
    white-space: pre-wrap;
  }
</style>
