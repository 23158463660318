export default {

  name: 'ChartColors',

  getColor4Type(type) {
    let colorSet = [
      {type: 'U3kinder', color: '#8fbc8f'},
      {type: 'NONE', color: '#EEEEEE'},
      {type: 'UNDEFINED', color: '#AAAAAA'},
      {type: '', color: '#efa7b3'},
      {type: 'Migration', color: '#ff6384'},
      {type: '', color: '#f44336'},
      {type: 'Mindestanforderung', color: '#ff9f40'},
      {type: 'Behinderung', color: '#ffcd56'},
      {type: '', color: '#009688'},
      {type: '', color: '#e91e63'},
      {type: 'Anstellungsschlüssel', color: '#4bc0c0'},
      {type: 'Regelkinder', color: '#36a2eb'},
      {type: '', color: '#146ebd'},
      {type: '', color: '#ccb2ff'},
      {type: '', color: '#ffcf9f'},
      {type: 'Summe', color: '#42ea50'},
      {type: 'Vorschul-/Zuschusskinder', color: '#9966ff'},
      {type: '', color: '#4bc0c0'},
      {type: '', color: '#c04ba6'},
      {type: 'Schulkinder', color: '#c0674b'},
      {type: '', color: '#795548'},
    ];
    for (let i in colorSet) {
      if (colorSet[i].type === type) {
        return colorSet[i].color;
      }
    }
    return colorSet[0].color;
  }
}