<template>
  <div v-if="statistic">
    <div class="grid-x">
      <div class="cell" style="padding-left: 5%;">
        <BarChart v-if="statisticBarChartData && statisticBarChartOptions"
                  :chartData="statisticBarChartData" style="max-height: 300px"
                  :options="statisticBarChartOptions"></BarChart>
      </div>
      <div class="cell large-6 medium-6 small-12" style="padding-left: 8%">
        <md-switch class="md-primary" v-model="stackedBarChart" @change="initData()">
          <p>Bar-Chart gestapelt</p>
        </md-switch>
        <br><br>
      </div>
      <div class="cell large-6 medium-6 small-12" style="padding-left: 8%">
      </div>
      <div class="cell">
        <table>
          <tr>
            <th class="stats-label"><i>Kinder</i></th>
            <th>
              <md-card class="md-primary blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">Ø Jahr</i>
                    <md-tooltip>Bewilligungsjahr</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th v-for="(item, index) of statistic.kibigMonthlyData" :key="index">
              <md-card class="md-primary blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">{{$material.locale.shortMonths[item.month - 1]}} {{String(item.year).substr(2,2)}}</i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
          </tr>
          <tr>
            <th style="width: 8%">
              <md-card class="md-primary blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">Regelkinder</i>
                    <md-tooltip>Regelkinder</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th>
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"><b>{{extRoundDe(statistic.avgNumRegelkind)}}</b></i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th v-for="(item, index) of statistic.kibigMonthlyData" :key="index">
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">{{extRoundDe(item.numRegelkind)}}</i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
          </tr>
          <tr>
            <th style="width: 8%">
              <md-card class="md-primary blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">Schulkinder</i>
                    <md-tooltip>Schulkinder</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th>
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"><b>{{extRoundDe(statistic.avgNumSchulkind)}}</b></i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th v-for="(item, index) of statistic.kibigMonthlyData" :key="index">
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">{{extRoundDe(item.numSchulkind)}}</i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
          </tr>
          <tr>
            <th style="width: 8%">
              <md-card class="md-primary blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">Migration</i>
                    <md-tooltip>Migration</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th>
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"><b>{{extRoundDe(statistic.avgNumMigrationskind)}}</b></i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th v-for="(item, index) of statistic.kibigMonthlyData" :key="index">
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">{{extRoundDe(item.numMigration)}}</i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
          </tr>
          <tr>
            <th style="width: 8%">
              <md-card class="md-primary blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">U3 Kinder</i>
                    <md-tooltip>U3 Kinder</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th>
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"><b>{{extRoundDe(statistic.avgNumU3kind)}}</b></i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th v-for="(item, index) of statistic.kibigMonthlyData" :key="index">
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">{{extRoundDe(item.numU3kinder)}}</i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
          </tr>
          <tr>
            <th style="width: 8%">
              <md-card class="md-primary blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">Förderkinder</i>
                    <md-tooltip>Förderkinder</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th>
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"><b>{{extRoundDe(statistic.avgNumBehindertkind)}}</b></i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th v-for="(item, index) of statistic.kibigMonthlyData" :key="index">
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">{{extRoundDe(item.numBehinderung)}}</i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
          </tr>
          <tr>
            <th style="width: 8%">
              <md-card class="md-primary blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"><b>Summe</b></i>
                    <md-tooltip>Summe</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th>
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"><b>{{extRoundDe(statistic.avgSum)}}</b></i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th v-for="(item, index) of statistic.kibigMonthlyData" :key="index">
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"><b>{{extRoundDe(item.sum)}}</b></i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
          </tr>
          <tr>
            <th style="width: 8%">
              <md-card class="md-primary blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">Anteil&nbsp;</i><i class="fi-wheelchair" style="font-size: 13px"></i>
                    <md-tooltip>Anteil Kinder mit Behinderung</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th>
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">{{extRoundDe(statistic.avgPercentageBehinderung)}} %</i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th v-for="(item, index) of statistic.kibigMonthlyData" :key="index">
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">{{extRoundDe(item.percentageBehinderung)}} %</i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
          </tr>
          <tr>
            <th style="width: 8%">
              <md-card class="md-primary blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">#&nbsp;Zuschuss</i>
                    <md-tooltip>Anzahl Kinder Beitragszuschuss</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th>
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">{{extRoundDe(statistic.avgNumVorschulkinderOrZuschussKinder)}}</i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th v-for="(item, index) of statistic.kibigMonthlyData" :key="index">
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">{{extRoundDe(item.numVorschulkinderOrZuschussKinder)}}</i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
          </tr>
          <tr>
            <th style="width: 8%">&nbsp;</th>
          </tr>
          <tr>
            <th class="stats-label"><i>Buchungen</i></th>
            <th>
              <md-card class="md-primary blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">Ø Jahr</i>
                    <md-tooltip>Bewilligungsjahr</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th v-for="(item, index) of statistic.kibigMonthlyData" :key="index">
              <md-card class="md-primary blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">{{$material.locale.shortMonths[item.month - 1]}} {{String(item.year).substr(2,2)}}</i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
          </tr>
          <tr>
            <th style="width: 8%">
              <md-card class="md-primary blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">ungewichtet</i>
                    <md-tooltip>Buchungen ungewichtet</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th>
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"><b>{{extRoundDe(statistic.avgBookingsUnweighted)}}</b></i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th v-for="(item, index) of statistic.kibigMonthlyData" :key="index">
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"><b>{{extRoundDe(item.bookingsUnweighted)}}</b></i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
          </tr>
          <tr>
            <th style="width: 8%">
              <md-card class="md-primary blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">gewichtet</i>
                    <md-tooltip>Buchungen gewichtet</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th>
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"><b>{{extRoundDe(statistic.avgBookingsWeighted)}}</b></i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th v-for="(item, index) of statistic.kibigMonthlyData" :key="index">
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"><b>{{extRoundDe(item.bookingsWeighted)}}</b></i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
          </tr>
          <tr>
            <th style="width: 8%">
              <md-card class="md-primary blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"> > 3 Stunden </i>
                    <md-tooltip>Anteil > 3 Stunden</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th>
              &nbsp;
            </th>
            <th v-for="(item, index) of statistic.kibigMonthlyData" :key="index">
              <md-card class="md-primary" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">{{extRoundDe(item.percentageMoreThan3Hours)}} %</i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import Spinner from 'vue-simple-spinner';
  import BarChart from '../../components/charts/BarChart';
  import ChartColors from '../../components/charts/ChartColors';
  
  export default {
    name: 'ChildrenStatisticView',
    components: {
      'vue-simple-spinner': Spinner,
      BarChart,
      ChartColors,
    },
    props: {
      statistic: {
        required: true
      },
      facility: {
        required: true
      },
      sending: {
        required: true
      },
      year: {
        required: true
      },
    },
    
    mounted() {
      this.initData();
    },

    watch: {
      statistic() {
        this.initData();
      },
      facility() {
        this.initData();
      },
    },

    data() {
      return {
        stackedBarChart: false,
        statisticBarChartData: null,
        statisticBarChartOptions: null,
      }
    },

    methods: {

      initData() {
        if (this.statistic) {
          this.buildStatisticChart();
        }
      },

      extRound(num) {
        num = (Math.round(num * 10) / 10);
        return num;
      },

      extRoundDe(num) {
        num = (Math.round(num * 10) / 10);
        return String(num).replace('.', ',');
      },

      buildStatisticChart() {
        // Chartjs
        this.statisticBarChartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: this.stackedBarChart
            }],
            yAxes: [{
              stacked: this.stackedBarChart
            }]
          },
          legend: {
            display: true,
            position: 'bottom',
          },
          title: {
            display: true,
            text: 'Übersicht Kinder',
          },
          layout: {
            padding: {
              top: 10,
              bottom: 0,
            },
          },
        };

        let datasets = [];
        let labels = [];

        labels.push('Ø Jahr');
        for (let item of this.statistic.kibigMonthlyData) {
          labels.push(this.$material.locale.shortMonths[item.month - 1] + ' ' + String(item.year).substr(2,2));
        }

        let data = [];
        data.push(this.extRound(this.statistic.avgSum));
        for (let item of this.statistic.kibigMonthlyData) {
          data.push(item.sum);
        }
        let set = {
          label: 'Summe',
          backgroundColor: ChartColors.getColor4Type('Summe'),
          data: data,
        };
        if (this.stackedBarChart) {
          set.stack = 'Stack 0'
        }
        datasets.push(set);

        data = [];
        data.push(this.extRound(this.statistic.avgNumRegelkind));
        for (let item of this.statistic.kibigMonthlyData) {
          data.push(item.numRegelkind);
        }
        set = {
          label: 'Regelkinder',
          backgroundColor: ChartColors.getColor4Type('Regelkinder'),
          data: data,
        };
        if (this.stackedBarChart) {
          set.stack = 'Stack 1'
        }
        datasets.push(set);

        data = [];
        data.push(this.extRound(this.statistic.avgNumSchulkind));
        for (let item of this.statistic.kibigMonthlyData) {
          data.push(item.numSchulkind);
        }
        set = {
          label: 'Schulkinder',
          backgroundColor: ChartColors.getColor4Type('Schulkinder'),
          data: data,
        };
        if (this.stackedBarChart) {
          set.stack = 'Stack 1'
        }
        datasets.push(set);

        data = [];
        data.push(this.extRound(this.statistic.avgNumMigrationskind));
        for (let item of this.statistic.kibigMonthlyData) {
          data.push(item.numMigration);
        }
        set = {
          label: 'Migration',
          backgroundColor: ChartColors.getColor4Type('Migration'),
          data: data,
        };
        if (this.stackedBarChart) {
          set.stack = 'Stack 1'
        }
        datasets.push(set);

        data = [];
        data.push(this.extRound(this.statistic.avgNumU3kind));
        for (let item of this.statistic.kibigMonthlyData) {
          data.push(item.numU3kinder);
        }
        set = {
          label: 'U3 Kinder',
          backgroundColor: ChartColors.getColor4Type('U3kinder'),
          data: data,
        };
        if (this.stackedBarChart) {
          set.stack = 'Stack 1'
        }
        datasets.push(set);

        data = [];
        data.push(this.extRound(this.statistic.avgNumBehindertkind));
        for (let item of this.statistic.kibigMonthlyData) {
          data.push(item.numBehinderung);
        }
        set = {
          label: 'Förderkinder',
          backgroundColor: ChartColors.getColor4Type('Behinderung'),
          data: data,
        };
        if (this.stackedBarChart) {
          set.stack = 'Stack 1'
        }
        datasets.push(set);

        data = [];
        data.push(this.extRound(this.statistic.avgNumVorschulkinderOrZuschussKinder));
        for (let item of this.statistic.kibigMonthlyData) {
          data.push(item.numVorschulkinderOrZuschussKinder);
        }
        set = {
          label: 'Vorschul-/Zuschusskinder',
          backgroundColor: ChartColors.getColor4Type('Vorschul-/Zuschusskinder'),
          data: data,
        };
        if (this.stackedBarChart) {
          set.stack = 'Stack 2'
        }
        datasets.push(set);

        this.statisticBarChartData = {
          labels: labels,
          datasets: datasets,
        };
      },
    },
  }
</script>

<style lang="scss" scoped>
  .md-tooltip {
    font-size: medium;
    height: auto;
    max-width: 500px;
    white-space: pre-wrap;
  }

  table {
    border-collapse: separate;
    empty-cells: hide;
    width: 100% !important;
    margin: 0;
    border-spacing: 1px;
  }

  th {
    color: grey;
    padding: 0;
    font-size: large;
    font-weight: lighter;
    width: 7.076923076923077%;
  }

  th:hover {
    cursor: pointer;
  }
</style>

<style lang="scss" scoped>
@import "../../../node_modules/vue-material/src/theme/engine";

@include md-register-theme("blue-card", (
    primary: md-get-palette-color(blue, 100)
));

@import "../../../node_modules/vue-material/src/base/theme";
@import "../../../node_modules/vue-material/src/components/MdCard/theme";

.md-tooltip {
  @include md-theme-component() {
    @include md-theme-property(color, text-primary, tooltip);
    @include md-theme-property(background-color, tooltip, background, .9);
  }
}

.md-theme-default th .md-card {
  background: whitesmoke;
  color: black;
}

.md-theme-dark th .md-card  {
  background: #666666;
  color: antiquewhite;
}

.md-theme-default th .md-card.md-primary.blue-card  {
  background: #bbdefb !important;
  color: black;
}

.md-theme-dark th .md-card.md-primary.blue-card  {
  background: #b9f6cb;
  color: #424242;
}

.md-theme-default .stats-label {
  color: #4b9cd2;
  width: 8%;
  font-size: 13px;
}

.md-theme-dark .stats-label {
  color: #ace0ac;
  width: 8%;
  font-size: 13px;
}

</style>
