<template>
  <div v-if="menu" class="hide-for-small-only">

    <fab position="top-left" ripple-color="dark"
         @addChild="onAddChild" @deleteChild="onDeleteChild" @addSibling="onAddSibling"
         @changeExitDate="onChangeExitDate" @transferToFacility="onTransferToFacility"
         @moveToFacility="onMoveToFacility" @archiveChild="onArchiveChild"
         toggle-when-away="" start-opened="" z-index="4"
         main-icon="menu" main-tooltip="Funktionsmenü" :actions="fabActions">
    </fab>

  </div>
</template>

<script>
  import FAB from 'vue-fab';
  import jQuery from 'jquery';

  export default {
    name: 'StickyMenu',

    components: {
      fab: FAB,
    },

    props: {
      menu: {
        required: true
      },
    },

    mounted() {
      jQuery('#top-left-wrapper').attr('style', '');
      jQuery('.fab-wrapper').attr('style', 'z-index:4;')
      jQuery('#top-left-wrapper').addClass('sticky-menu-position');
    },

    data() {
      return {
        showBadge: false,

        fabActions: [
          {
            name: 'addChild', icon: 'person_add', tooltip: 'Neues Kind hinzufügen', color: 'green'
          },
          {
            name: 'addSibling', icon: 'group_add', tooltip: 'Geschwister anlegen', color: 'cornflowerblue',
          },
          {
            name: 'deleteChild', icon: 'delete', tooltip: 'Datensatz Kind löschen', color: 'crimson',
          },
          {
            name: 'changeExitDate', icon: 'exit_to_app', tooltip: 'Austrittsdatum für Kind ändern', color: 'orange',
          },
          {
            name: 'transferToFacility', icon: 'arrow_forward', tooltip: 'Übertritt für Kind durchführen', color: 'rebeccapurple',
          },
          {
            name: 'moveToFacility', icon: 'autorenew', tooltip: 'Wechsel für Kind durchführen', color: 'mediumblue',
          },
          {
            name: 'archiveChild', icon: 'archive', tooltip: 'Kind archivieren', color: '#80705c',
          },
        ],
      }
    },

    methods: {
      onAddChild() {
        this.$emit("addChild");
      },

      onDeleteChild() {
        this.$emit("deleteChild");
      },

      onAddSibling() {
        this.$emit("addSibling");
      },

      onChangeExitDate() {
        this.$emit("changeExitDate");
      },

      onTransferToFacility() {
        this.$emit("transferToFacility");
      },

      onMoveToFacility() {
        this.$emit("moveToFacility");
      },

      onArchiveChild() {
        this.$emit("archiveChild");
      }
    },
  }
</script>

<style type="text/css" lang="scss">

  .md-tooltip {
    font-size: medium;
    white-space: normal;
  }

  .sticky-menu-position {
    transition: opacity 0.25s ease-in-out, left 0.25s ease-in-out, top 0.25s ease-in-out;
    opacity: 0.75;
    left: 0.3rem;
    top: 13rem;
    z-index: 4;
    position: fixed;
  }

  .material-icons {
    color: #424242;
  }

  .fab-tooltip.tooltip {
    display: block !important;
    padding: 10px;
    background: transparent;
    z-index: 10000;
  }
  .fab-tooltip.tooltip .tooltip-inner {
    background: #333333;
    color: white;
    border-radius: 0px;
    padding: 5px 10px 4px;
    border-width: 0;
    font-size: 1rem;
  }

</style>