<template>
  <div v-if="statistic">
    <div class="grid-x">
      <div class="cell">
        <BarChart v-if="statisticBarChartData && statisticBarChartOptions"
                  :chartData="statisticBarChartData" style="max-height: 300px"
                  :options="statisticBarChartOptions"></BarChart>
      </div>
      <div class="cell large-6 medium-6 small-6" style="padding-left: 32px">
<!--        <md-switch class="md-primary" v-model="stackedBarChart" @change="initData()">
          <p>Bar-Chart gestapelt</p>
        </md-switch>-->
        <br><br>
      </div>
      <div class="cell large-6 medium-6 small-6"></div>
      <div class="cell">
        <table>
          <tr>
            <th style="width: 4%">&nbsp;</th>
            <th>
              <md-card class="md-primary" md-theme="blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">Ø Jahr</i>
                    <md-tooltip>Bewilligungsjahr</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th v-for="(item, index) of statistic.kibigMonthlyData" :key="index">
              <md-card class="md-primary" md-theme="blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">{{$material.locale.shortMonths[item.month - 1]}} {{String(item.year).substr(2,2)}}</i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
          </tr>
          <tr>
            <th style="width: 4%">
              <md-card class="md-primary" md-theme="blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">&sum;FKS</i>
                    <md-tooltip>Summe FKS</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th>
              <md-card class="md-primary" md-theme="grey-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"><b>{{extRoundDe(statistic.avgSummeFKS)}}</b></i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th v-for="(item, index) of statistic.kibigMonthlyData" :key="index">
              <md-card class="md-primary" md-theme="grey-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">{{extRoundDe(item.summeFKS)}}</i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
          </tr>
          <tr>
            <th style="width: 4%">
              <md-card class="md-primary" md-theme="blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">&sum;EKS</i>
                    <md-tooltip>Summe EKS</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th>
              <md-card class="md-primary" md-theme="grey-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"><b>{{extRoundDe(statistic.avgSummeEKS)}}</b></i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th v-for="(item, index) of statistic.kibigMonthlyData" :key="index">
              <md-card class="md-primary" md-theme="grey-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">{{extRoundDe(item.summeEKS)}}</i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
          </tr>
          <tr>
            <th style="width: 4%">
              <md-card class="md-primary" md-theme="blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">h/W</i>
                    <md-tooltip>Personal (h/Woche)</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th>
              <md-card class="md-primary" md-theme="grey-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"><b>{{extRoundDe(statistic.workingHoursPerWeek)}}</b></i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th v-for="(item, index) of statistic.kibigMonthlyData" :key="index">
              <md-card class="md-primary" md-theme="grey-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"><b>{{extRoundDe(item.workingHoursPerWeek)}}</b></i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
          </tr>
          <tr>
            <th style="width: 4%">
              <md-card class="md-primary" md-theme="blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">PpP</i>
                    <md-tooltip>Puffer pädagogisches Personal</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th>
            </th>
            <th v-for="(item, index) of statistic.kibigMonthlyData" :key="index">
              <md-card class="md-primary" md-theme="grey-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">{{extRoundDe(item.pufferPersonal)}}</i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
          </tr>
          <tr>
            <th style="width: 4%">
              <md-card class="md-primary" md-theme="blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">AS</i>
                    <md-tooltip>Anstellungsschlüssel</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th>
              <md-card class="md-primary" md-theme="grey-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"><b>{{extRoundDe(statistic.anstellungsschluessel)}}</b></i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th v-for="(item, index) of statistic.kibigMonthlyData" :key="index">
              <md-card class="md-primary" md-theme="grey-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"><b>{{extRoundDe(item.anstellungsschluessel)}}</b>&nbsp;</i>&nbsp;&nbsp;
                    <span v-if="item.anstellungsschluesselOK" style="color: forestgreen; font-size: 12px;">&#10003;</span>
                    <span v-else style="color: red; font-size: 18px;">&#8855;</span>
                    <md-tooltip v-if="item.anstellungsschluesselOK" style="color: greenyellow">Anstellungsschlüssel in Ordnung</md-tooltip>
                    <md-tooltip v-else style="color: yellow">Anstellungsschlüssel NICHT in Ordnung</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
          </tr>
          <tr>
            <th style="width: 4%">
              <md-card class="md-primary" md-theme="blue-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px">PFQ</i>
                    <md-tooltip>Puffer Fachkraftquote</md-tooltip>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th>
              <md-card class="md-primary" md-theme="grey-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"><b>{{extRoundDe(statistic.avgPufferFachkraftquote)}}</b></i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
            <th v-for="(item, index) of statistic.kibigMonthlyData" :key="index">
              <md-card class="md-primary" md-theme="grey-card" style="height: 24px">
                <md-ripple>
                  <div class="md-title" style="line-height: 18px;">
                    <i style="font-size: 12px"><b>{{extRoundDe(item.pufferFachkraftquote)}}</b>&nbsp;</i>
                  </div>
                </md-ripple>
              </md-card>
            </th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import Spinner from 'vue-simple-spinner';
  import BarChart from '../../components/charts/BarChart';

  export default {
    name: 'EmployeesStatisticView',
    components: {
      'vue-simple-spinner': Spinner,
      BarChart,
    },
    props: {
      statistic: {
        required: true
      },
      facility: {
        required: true
      },
      sending: {
        required: true
      },
      year: {
        required: true
      },
    },

    mounted() {
      this.initData();
    },

    watch: {
      statistic() {
        this.initData();
      },
      facility() {
        this.initData();
      },
    },

    data() {
      return {
        stackedBarChart: false,
        statisticBarChartData: null,
        statisticBarChartOptions: null,
      }
    },

    methods: {

      initData() {
        if (this.statistic) {
          this.buildStatisticChart();
        }
      },

      extRound(num) {
        num = (Math.round(num * 10) / 10);
        return num;
      },

      extRoundDe(num) {
        num = (Math.round(num * 10) / 10);
        return String(num).replace('.', ',');
      },

      buildStatisticChart() {
        // Chartjs
        this.statisticBarChartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: this.stackedBarChart
            }],
            yAxes: [{
              stacked: this.stackedBarChart
            }]
          },
          legend: {
            display: true,
            position: 'bottom',
          },
          title: {
            display: true,
            text: 'Übersicht Mitarbeiter',
          },
          layout: {
            padding: {
              top: 10,
              bottom: 0,
            },
          },
        };

        let datasets = [];
        let labels = [];

        labels.push('Ø Jahr');
        for (let item of this.statistic.kibigMonthlyData) {
          labels.push(this.$material.locale.shortMonths[item.month - 1] + ' ' + String(item.year).substr(2,2));
        }

        let data = [];
        data.push(this.extRound(this.statistic.avgSummeFKS));
        for (let item of this.statistic.kibigMonthlyData) {
          data.push(this.extRound(item.summeFKS));
        }
        let set = {
          label: 'Summe FKS',
          backgroundColor: this.getColor4Type('SummeFKS'),
          data: data,
        };
        if (this.stackedBarChart) {
          set.stack = 'Stack 0'
        }
        datasets.push(set);

        data = [];
        data.push(this.extRound(this.statistic.avgSummeEKS));
        for (let item of this.statistic.kibigMonthlyData) {
          data.push(this.extRound(item.summeEKS));
        }
        set = {
          label: 'Summe EKS',
          backgroundColor: this.getColor4Type('SummeEKS'),
          data: data,
        };
        if (this.stackedBarChart) {
          set.stack = 'Stack 0'
        }
        datasets.push(set);

        data = [];
        data.push(this.extRound(this.statistic.workingHoursPerWeek));
        for (let item of this.statistic.kibigMonthlyData) {
          data.push(this.extRound(item.workingHoursPerWeek));
        }
        set = {
          label: 'Personal (h/Woche)',
          backgroundColor: this.getColor4Type('PersonalStundenProWoche'),
          data: data,
        };
        if (this.stackedBarChart) {
          set.stack = 'Stack 1'
        }
        datasets.push(set);

        data = [];
        data.push(this.extRound(this.statistic.avgPufferPersonal));
        for (let item of this.statistic.kibigMonthlyData) {
          data.push(this.extRound(item.pufferPersonal));
        }
        set = {
          label: 'Puffer päd. Personal',
          backgroundColor: this.getColor4Type('PufferPersonal'),
          data: data,
        };
        if (this.stackedBarChart) {
          set.stack = 'Stack 1'
        }
        datasets.push(set);

        data = [];
        data.push(this.extRound(this.statistic.anstellungsschluessel));
        for (let item of this.statistic.kibigMonthlyData) {
          data.push(this.extRound(item.anstellungsschluessel));
        }
        set = {
          label: 'Anstellungsschlüssel',
          backgroundColor: this.getColor4Type('Anstellungsschluessel'),
          data: data,
        };
        if (this.stackedBarChart) {
          set.stack = 'Stack 2'
        }
        datasets.push(set);

        data = [];
        data.push(this.extRound(this.statistic.avgPufferFachkraftquote));
        for (let item of this.statistic.kibigMonthlyData) {
          data.push(this.extRound(item.pufferFachkraftquote));
        }
        set = {
          label: 'Puffer Fachkraftquote',
          backgroundColor: this.getColor4Type('PufferFachkraftquote'),
          data: data,
        };
        if (this.stackedBarChart) {
          set.stack = 'Stack 2'
        }
        datasets.push(set);

        this.statisticBarChartData = {
          labels: labels,
          datasets: datasets,
        };
      },

      getColor4Type(type) {
        let colorSet = [
          {type: 'UNDEFINED', color: '#AAAAAA'},
          {type: '', color: '#efa7b3'},
          {type: 'Anstellungsschluessel', color: '#ff6384'},
          {type: '', color: '#f44336'},
          {type: '', color: '#ff9f40'},
          {type: 'Behinderung', color: '#ffcd56'},
          {type: 'SummeEKS', color: '#009688'},
          {type: '', color: '#e91e63'},
          {type: '', color: '#4bc0c0'},
          {type: 'PersonalStundenProWoche', color: '#36a2eb'},
          {type: '', color: '#146ebd'},
          {type: '', color: '#ccb2ff'},
          {type: '', color: '#ffcf9f'},
          {type: 'SummeFKS', color: '#42ea50'},
          {type: 'U3kinder', color: '#8fbc8f'},
          {type: 'PufferFachkraftquote', color: '#9966ff'},
          {type: '', color: '#4bc0c0'},
          {type: '', color: '#c04ba6'},
          {type: 'PufferPersonal', color: '#c0674b'},
          {type: '', color: '#795548'},
        ];
        for (let i in colorSet) {
          if (colorSet[i].type === type) {
            return colorSet[i].color;
          }
        }
        return colorSet[0].color;
      }
    },
  }
</script>

<style lang="scss" scoped>

  .md-tooltip {
    font-size: medium;
    height: auto;
    max-width: 500px;
    white-space: pre-wrap;
  }

  table {
    border-collapse: separate;
    empty-cells: hide;
    width: 100% !important;
    margin: 0;
    border-spacing: 1px;
  }

  th {
    color: grey;
    padding: 0;
    font-size: large;
    font-weight: lighter;
    width: 7.38461538%;
  }

  th:hover {
    cursor: pointer;
  }
</style>

<style lang="scss" scoped>
  @import "../../../node_modules/vue-material/src/theme/engine";

  @include md-register-theme("grey-card", (
      primary: md-get-palette-color(grey, 100)
  ));

  @import "../../../node_modules/vue-material/src/base/theme";
  @import "../../../node_modules/vue-material/src/components/MdCard/theme";

  .md-tooltip {
    @include md-theme-component() {
      @include md-theme-property(color, text-primary, tooltip);
      @include md-theme-property(background-color, tooltip, background, .9);
    }
  }

  @include md-register-theme("blue-card", (
      primary: md-get-palette-color(blue, 100)
  ));

  @import "../../../node_modules/vue-material/src/base/theme";
  @import "../../../node_modules/vue-material/src/components/MdCard/theme";

  .md-tooltip {
    @include md-theme-component() {
      @include md-theme-property(color, text-primary, tooltip);
      @include md-theme-property(background-color, tooltip, background, .9);
    }
  }
</style>
