<template>
  <div v-if="statistic">
    <div class="grid-x">
      <div class="cell large-5 medium-5 small-12">
        <div class="md-title" style="font-size: x-large; margin: 0;">
          {{facility.name}}
          <span v-if="simulationActive" style="font-size: large;">&nbsp;&nbsp;&nbsp;<b class="flash-orange-red"><i>Simulation aktiv</i></b>
            <md-tooltip>Es werden aktuell simulierte Daten angezeigt.</md-tooltip>
          </span>
        </div>
      </div>
      <div v-if="simulationActive" class="cell large-6 medium-6 small-12 text-center"></div>
      <div v-else class="cell large-6 medium-6 small-12 text-center">
        <md-button class="md-icon-button md-dense md-primary" @click="onPreviousYear">
          <md-icon>chevron_left</md-icon>
          <md-tooltip>vorheriges Jahr</md-tooltip>
        </md-button>
        <span class="md-title"
              style="font-size: x-large; color: cornflowerblue; width: 210px;display: inline-block;text-align: center;">&nbsp;<b>{{year}}</b>&nbsp;</span>
        <md-button class="md-icon-button md-dense md-primary" @click="onNextYear">
          <md-icon>chevron_right</md-icon>
          <md-tooltip>nächstes Jahr</md-tooltip>
        </md-button>
      </div>
      <div class="cell large-1 medium-1 hide-for-small-only">
        <vue-simple-spinner v-if="sending > 0"></vue-simple-spinner>
      </div>
    </div>
    <br>
    <md-tabs md-elevation="1" md-active-tab="tab-children">
      <md-tab id="tab-children" md-label="Kinder">
        <ChildrenStatisticView :statistic="statistic" :sending="sending" :year="year" :facility="facility">
        </ChildrenStatisticView>
      </md-tab>
      <md-tab id="tab-employees" md-label="Mitarbeiter">
        <EmployeesStatisticView :statistic="statistic" :sending="sending" :year="year" :facility="facility">
        </EmployeesStatisticView>
      </md-tab>
    </md-tabs>
  </div>
</template>

<script>
  import Spinner from 'vue-simple-spinner';
  import ChildrenStatisticView from "./ChildrenStatisticView";
  import EmployeesStatisticView from "./EmployeesStatisticView";

  export default {
    name: 'StatisticView',
    components: {
      ChildrenStatisticView,
      EmployeesStatisticView,
      'vue-simple-spinner': Spinner,
    },
    props: {
      statistic: {
        required: true
      },
      facility: {
        required: true
      },
      sending: {
        required: true
      },
      year: {
        required: true
      },
      simulationActive: {
        required: true
      },
    },

    methods: {
      onPreviousYear() {
        this.$emit("previousYear");
      },

      onNextYear() {
        this.$emit("nextYear");
      },
    },

    computed: {
    }
  }
</script>

<style lang="scss" scoped>
  .md-tooltip {
    font-size: medium;
    height: auto;
    max-width: 500px;
    white-space: pre-wrap;
  }
</style>

