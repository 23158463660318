<template>
  <div v-if="simulationDataEntry" class="grid-x grid-padding-x">
    <div class="cell large-2 medium-3">
      <md-field :class="getValidationClass('number')">
        <label v-if="type === 'children'" for="number-name">Anzahl Kinder</label>
        <label v-else-if="type === 'employees'" for="number-name">Anzahl Mitarbeiter</label>
        <md-input name="number-name" id="number-name" autocomplete="off" v-model="simulationDataEntry.number" :disabled="sending || mode === 'delete'"/>
        <span class="md-error" v-if="!$v.simulationDataEntry.number.required">Anzahl ist notwendig</span>
      </md-field>
    </div>
    <div class="cell large-3 medium-3">
      <md-field :class="getValidationClass('hoursPerWeek')">
        <label for="hours-per-week-name">Wochenstunden</label>
        <md-input name="hours-per-week-name" id="hours-per-week-name" autocomplete="off" v-model="simulationDataEntry.hoursPerWeek" :disabled="sending || mode === 'delete'"/>
        <span class="md-error" v-if="!$v.simulationDataEntry.hoursPerWeek.required">Wochenstunden sind notwendig</span>
      </md-field>
    </div>
    <div class="cell large-2 medium-3">
      <md-field :class="getValidationClass('startMonth')">
        <label>Start Monat</label>
        <md-select v-model="simulationDataEntry.startMonth" :disabled="sending || mode === 'delete'">
          <md-option v-for="(month, index) of $material.locale.months" :value="index + 1" :key="month + index">
            {{month}}
          </md-option>
        </md-select>
      </md-field>
    </div>
    <div class="cell large-2 medium-3">
      <md-field :class="getValidationClass('endMonth')">
        <label>Ende Monat</label>
        <md-select v-model="simulationDataEntry.endMonth" :disabled="sending || mode === 'delete'">
          <md-option v-for="(month, index) of $material.locale.months" :value="index + 1" :key="month + index + 12">
            {{month}}
          </md-option>
        </md-select>
      </md-field>
    </div>
    <div v-if="type === 'children'" class="cell large-3 medium-6">
      <md-field :class="getValidationClass('supportFactor')">
        <label>Förderungsmerkmal</label>
        <md-select v-model="simulationDataEntry.supportFactor" :disabled="sending || mode === 'delete'">
          <md-option v-for="factor of labels.supportFactors" :value="factor" :key="factor">
            {{labels.supportFactor[factor]}}
          </md-option>
        </md-select>
      </md-field>
    </div>
    <div v-if="type === 'employees'" class="cell large-3 medium-6">
      <md-checkbox class="md-primary" style="padding-top: 10px" v-model="simulationDataEntry.fachkraft"><span style="font-size: 1rem">Fachkraft</span></md-checkbox>
    </div>
  </div>
</template>

<script>

  import {validationMixin} from 'vuelidate';
  import {
    required,
  } from 'vuelidate/lib/validators';

  export default {
    name: 'SimulationDataForm',
    mixins: [validationMixin],

    props: {
      simulationDataEntry: {
        required: true
      },
      sending: {
        required: true
      },
      mode: {
        required: true,
      },
      type: {
        required: true,
      }
    },

    data: () => ({
      invalid: true,
    }),

    validations: {
      simulationDataEntry: {
        number: {
          required,
        },
        hoursPerWeek: {
          required,
        },
      },
    },

    methods: {
      getValidationClass(fieldName) {
        let field = this.$v.simulationDataEntry[fieldName];

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      validateSimulationData() {
        this.$v.$touch();
        this.invalid = this.$v.$invalid;
      },
    },

    computed: {
      organization() {
        return this.$store.getters.organization;
      },

      labels() {
        return this.$store.getters.labels
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
