<template>
  <div>
    <md-dialog :md-click-outside-to-close="false" v-if="simulationDataEntry" :md-active.sync="showDialog" @md-closed="closeDialog">
      <form novalidate @submit.prevent="validateSimulationData">
        <md-dialog-title>
          <div class="grid-x grid-padding-x">
            <div v-if="mode === 'update'" class="cell large-5 medium-5">
              <md-icon style="color: black">create</md-icon>&nbsp;&nbsp;&nbsp;Eintrag für
              <span v-if="type === 'children'">Kinder</span>
              <span v-else-if="type === 'employees'">Mitarbeiter</span>
              bearbeiten
            </div>
            <div v-if="mode === 'delete'" class="cell large-5 medium-5">
              <md-icon style="color: black">delete</md-icon>&nbsp;&nbsp;&nbsp;Eintrag für
              <span v-if="type === 'children'">Kinder</span>
              <span v-else-if="type === 'employees'">Mitarbeiter</span>
              löschen
            </div>
            <div v-else-if="mode === 'add'" class="cell large-5 medium-5">
              <md-icon style="color: black">group_add</md-icon>&nbsp;&nbsp;&nbsp;Weitere
              <span v-if="type === 'children'">Kinder</span>
              <span v-else-if="type === 'employees'">Mitarbeiter</span>
              hinzufügen
            </div>
            <div class="cell large-1 medium-1 small-12" style="margin-top: -5px;">
              <SessionTime></SessionTime>
            </div>
            <div class="cell large-6 medium-6 text-center">
              <button v-if="mode === 'update'" class="button success" type="submit" :disabled="sending">
                <i class="fi-check"></i>&nbsp;&nbsp;&nbsp;Änderungen übernehmen
              </button>
              <button v-else-if="mode === 'add'" class="button success" type="submit" :disabled="sending">
                <i class="fi-plus"></i>&nbsp;&nbsp;&nbsp;Eintrag hinzufügen
              </button>
              <button v-if="mode !== 'delete'" class="button alert" style="margin-left: 1rem;" @click="closeDialog" type="button"
                      :disabled="sending">
                <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
              </button>
            </div>
          </div>
        </md-dialog-title>

        <md-dialog-content>
          <SimulationDataForm ref="simulationDataForm" :simulationDataEntry="simulationDataEntry"
                              :sending="sending" :mode="mode" :type="type"/>

          <md-progress-bar md-mode="indeterminate" v-if="sending"/>

          <div v-if="mode === 'delete'" class="text-center">
            <br>
            <p style="font-size: 1rem">Soll dieser Eintrag wirklich gelöscht werden?</p>

            <button class="button success" type="submit" :disabled="sending">
              <i class="fi-check"></i>&nbsp;&nbsp;&nbsp;Eintrag löschen
            </button>
            <button class="button alert" style="margin-left: 1rem;" @click="closeDialog" type="button"
                    :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
            </button>
          </div>
        </md-dialog-content>

      </form>

      <button class="close-button" type="button" @click="closeDialog">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>
  </div>
</template>

<script>


  import SimulationDataForm from '../forms/SimulationDataForm';
  /*
    import FacilityService from '../../services/FacilityService';
    import SimulationDataService from '../../services/SimulationDataService';
    import HttpErrorHandler from '../../services/HttpErrorHandler';
  */
  import SessionTime from '../animations/SessionTime';

  import {validationMixin} from 'vuelidate'

  export default {
    name: 'EditSimulationDataDialog',
    mixins: [validationMixin],

    components: {
      SimulationDataForm,
      SessionTime,
    },

    props: {
      simulationDataEntry: {
        required: true
      },
      showDialog: {
        required: true
      },
      mode: {
        required: true
      },
      type: {
        required: true
      },
    },

    created() {
    },

    data: () => ({
      success: true,
      sending: false,
    }),

    methods: {
      updateSimulationData() {
        this.$emit("updateSimulationDataEntry");
        this.closeDialog();
      },

      addSimulationData() {
        this.$emit("addSimulationDataEntry", this.simulationDataEntry);
        this.closeDialog();
      },

      deleteSimulationData() {
        this.$emit("deleteSimulationDataEntry", this.simulationDataEntry);
        this.closeDialog();
      },

      validateSimulationData() {
        if (this.mode === 'delete') {
          this.deleteSimulationData();
        }

        this.$refs.simulationDataForm.validateSimulationData();

        if (!this.$refs.simulationDataForm.invalid) {

          if (this.mode === 'update') {
            this.updateSimulationData();
          }
          if (this.mode === 'add') {
            this.addSimulationData();
          }
        }
      },

      closeDialog() {
        this.$emit("closeEditSimulationDataDialog");
      },
    },

    computed: {

      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      isAdmin() {
        return this.$store.getters.admin
      },

      isSage() {
        return this.$store.getters.sage
      },

      organization() {
        return this.$store.getters.organization;
      },
    },
  };
</script>

<style scoped>
  .md-dialog /deep/.md-dialog-container {
    min-width: 90%;
  }
</style>
