<template>
  <div v-if="assignmentPlan">
    <table>
      <tr>
        <td>
          <md-card md-with-hover>
            <md-card-expand>
              <md-card-actions md-alignment="space-between">
                <md-card-header style="padding: 6px; width: 95%">
                  <div class="grid-x">
                    <div class="cell large-5 medium-6 small-12">
                      <div class="md-title" style="font-size: x-large; margin: 0;">
                        {{facility.name}}
                      </div>
                    </div>
                    <div class="cell large-5 medium-6 small-12">
                      <md-button class="md-icon-button md-dense md-primary" @click="onPreviousMonth">
                        <md-icon>chevron_left</md-icon>
                        <md-tooltip>vorheriger Monat</md-tooltip>
                      </md-button>
                      <span class="md-title" style="font-size: large; color: cornflowerblue; min-width: 170px;display: inline-block;text-align: center;">
                        &nbsp;<span>{{getMonthStr(month)}}</span>&nbsp;<b style="font-size: x-large;">{{year}}</b>&nbsp;
                      </span>
                      <md-button class="md-icon-button md-dense md-primary" @click="onNextMonth">
                        <md-icon>chevron_right</md-icon>
                        <md-tooltip>nächster Monat</md-tooltip>
                      </md-button>
                    </div>
                    <div class="cell large-2 hide-for-medium-only hide-for-small-only">
                      <vue-simple-spinner v-if="sending > 0"></vue-simple-spinner>
                      <md-switch v-else class="md-primary" v-model="showAllDays" style="margin-bottom: 0; margin-top: 6px;">
                        <span style="font-size: smaller">Alle Tage</span>
                        <md-tooltip>Alle Tage anzeigen</md-tooltip>
                      </md-switch>
                    </div>
                  </div>
                </md-card-header>
                <md-card-expand-trigger>
                  <md-button class="md-icon-button">
                    <md-icon>keyboard_arrow_down</md-icon>
                    <md-tooltip>Detail-Informationen</md-tooltip>
                  </md-button>
                </md-card-expand-trigger>
              </md-card-actions>

              <md-card-expand-content>
                <md-card-content>
                  <div class="cell text-center">
                    <p>Mögliche Buchungszeiten : von <span class="color-span">{{facility.facilitySettings.bookingTimesStart.substr(0,5)}}</span>&nbsp;bis&nbsp;<span
                        class="color-span">{{facility.facilitySettings.bookingTimesEnd.substr(0,5)}}</span></p>
                    <p>Plätze laut Betriebserlaubnis : <span class="color-span">{{assignmentPlan.plaetzeLautBetriebserlaubnis}}</span>
                    </p>
                    <p>Maximale Anzahl Kinder : <span class="color-span">{{assignmentPlan.maxAllowedChildren}}</span>
                    </p>
                    <p>Plätze unter 3 Jahre : <span class="color-span">{{assignmentPlan.plaetzeU3}}</span></p>
                    <p>Plätze ab 3 Jahre bis Einschulung : <span
                        class="color-span">{{assignmentPlan.plaetze3bisEinschulung}}</span></p>
                    <p>Plätze Schulkinder : <span
                        class="color-span">{{assignmentPlan.plaetzeSchulkinder}}</span></p>
                  </div>
                </md-card-content>
              </md-card-expand-content>
            </md-card-expand>
          </md-card>
        </td>
      </tr>
    </table>
    <table>
      <tr>
        <th>
          <md-card class="md-primary" style="height: 50px">
            <md-ripple>
              <md-card-header>
                <div class="md-title">
                  <i>Gesamt</i>
                </div>
              </md-card-header>
            </md-ripple>
          </md-card>
        </th>
        <th v-for="(day, gIndex) of $material.locale.shortDays" :key="gIndex" v-if="assignmentPlan.dailyEntries[day.toUpperCase()] || showAllDays">
          <md-card style="height: 50px;" lass="md-primary" md-with-hover v-if="assignmentPlan.dailyEntries[day.toUpperCase()]">
            <md-ripple>

              <md-card-content>

                <div class="md-layout">
                  <div class="md-layout-item md-size-40">
                    <md-card class="md-primary md-card-total-load" :style="getStyle(day, '', 'TOTAL')">

                      <div v-if="assignmentPlan.dailyEntries[day.toUpperCase()].numChildren > assignmentPlan.plaetzeLautBetriebserlaubnis" class="md-subhead">
                        <b>{{assignmentPlan.dailyEntries[day.toUpperCase()].numChildren}}</b>
                      </div>
                      <div v-else-if="assignmentPlan.dailyEntries[day.toUpperCase()].numChildren > assignmentPlan.maxAllowedChildren" class="md-subhead">
                        <b>{{assignmentPlan.dailyEntries[day.toUpperCase()].numChildren}}</b>
                      </div>
                      <div v-else class="md-subhead">{{assignmentPlan.dailyEntries[day.toUpperCase()].numChildren}}</div>

                      <md-content :style="getStyle(day, '', 'RED')"></md-content>
                      <md-content :style="getStyle(day, '', 'ORANGE')"></md-content>

                      <md-tooltip md-delay="250">{{day.toUpperCase()}} <b>{{startIntervals}}-{{endIntervals}}</b> Uhr<br><br><b>{{assignmentPlan.dailyEntries[day.toUpperCase()].numChildren}}</b> Kinder insgesamt</md-tooltip>
                    </md-card>
                  </div>
                  <div class="md-layout-item md-size-60">
                    <md-icon v-if="assignmentPlan.dailyEntries[day.toUpperCase()].numChildren > assignmentPlan.maxAllowedChildren" style="color: orange">error_outline
                      <md-tooltip>Die maximale Anzahl von <b>{{assignmentPlan.maxAllowedChildren}}</b> Kindern wurde um
                        <b>{{assignmentPlan.dailyEntries[day.toUpperCase()].numChildren - assignmentPlan.maxAllowedChildren}}</b> überschritten!</md-tooltip>
                    </md-icon>
                    <md-icon v-else style="color: darkseagreen">check_circle_outline
                      <md-tooltip>Die maximale Anzahl von <b>{{assignmentPlan.maxAllowedChildren}}</b> Kindern ist <b>eingehalten</b>.</md-tooltip>
                    </md-icon>
                    <md-icon v-if="assignmentPlan.dailyEntries[day.toUpperCase()].numChildren > assignmentPlan.plaetzeLautBetriebserlaubnis" style="color: orangered">error_outline
                      <md-tooltip>Die <b>{{assignmentPlan.plaetzeLautBetriebserlaubnis}}</b> Plätze laut Betriebserlaubnis wurden um
                        <b>{{assignmentPlan.dailyEntries[day.toUpperCase()].numChildren - assignmentPlan.plaetzeLautBetriebserlaubnis}}</b> überschritten!</md-tooltip>
                    </md-icon>
                    <md-icon v-else style="color: darkseagreen">check_circle_outline
                      <md-tooltip>Die <b>{{assignmentPlan.plaetzeLautBetriebserlaubnis}}</b> Plätze laut Betriebserlaubnis sind <b>eingehalten</b>.</md-tooltip>
                    </md-icon>
                  </div>
                </div>

              </md-card-content>

            </md-ripple>

          </md-card>
        </th>
      </tr>
      <tr>
        <th>
          <md-card class="md-primary" style="height: 46px">
            <md-ripple>
              <md-card-header>
                <div class="md-title">
                  <i>Zeit</i>
                </div>
              </md-card-header>
            </md-ripple>
          </md-card>
        </th>
        <th v-for="(day, index) of $material.locale.days" :key="index" v-if="assignmentPlan.dailyEntries[day.toUpperCase().substr(0, 2)] || showAllDays">
          <md-card class="md-primary" style="height: 46px">
            <md-ripple>
              <md-card-header>
                <div class="md-title">
                  <i>{{day}}</i>
                </div>
              </md-card-header>
            </md-ripple>
          </md-card>
        </th>
      </tr>
      <tr v-for="(interval, dIndex) of hoursIntervals" :key="dIndex">
        <th>
          <md-card class="md-primary" style="height: 50px">
            <md-ripple>
              <md-card-header>
                <div class="md-title">
                  <i>{{interval}} </i>
                  <span class="md-subhead">Uhr</span>
                </div>
              </md-card-header>
              <md-card-content>
              </md-card-content>
            </md-ripple>
          </md-card>
        </th>
        <td v-for="(day, dIndex) of $material.locale.shortDays" :key="dIndex" v-if="assignmentPlan.dailyEntries[day.toUpperCase()] || showAllDays"
            @click="selectDailyEntry(assignmentPlan.dailyEntries[day.toUpperCase()].hourEntries[interval], day, interval)">

          <md-card style="height: 50px;"
                   v-if="assignmentPlan.dailyEntries[day.toUpperCase()] && assignmentPlan.dailyEntries[day.toUpperCase()].hourEntries[interval]"
                   class="md-primary" md-with-hover>
            <md-ripple :style="getSelectedEntryStyle(day, interval)">

              <md-card-content>

                <md-card v-if="selectedDivisions.includes('KRIPPE')" class="md-primary md-card-load" :style="getStyle(day, interval, 'KRIPPE')">
                  <div class="md-subhead">{{assignmentPlan.dailyEntries[day.toUpperCase()].hourEntries[interval].numChildrenKrippe}}</div>
                </md-card>
                <md-card v-if="selectedDivisions.includes('KINDERGARTEN')" class="md-primary md-card-load" :style="getStyle(day, interval, 'KINDERGARTEN')">
                  <div class="md-subhead">{{assignmentPlan.dailyEntries[day.toUpperCase()].hourEntries[interval].numChildrenKindergarten}}</div>
                </md-card>
                <md-card v-if="selectedDivisions.includes('HORT')" class="md-primary md-card-load" :style="getStyle(day, interval, 'HORT')">
                  <div class="md-subhead">{{assignmentPlan.dailyEntries[day.toUpperCase()].hourEntries[interval].numChildrenHort}}</div>
                </md-card>

              </md-card-content>
            </md-ripple>

            <md-tooltip md-delay="250">{{day.toUpperCase()}} <b>{{interval}}</b> Uhr<br><br>
              <b>{{assignmentPlan.dailyEntries[day.toUpperCase()].hourEntries[interval].numChildrenKrippe}}</b> Krippen-Kind<span v-if="assignmentPlan.dailyEntries[day.toUpperCase()].hourEntries[interval].numChildrenKrippe !== 1">er</span><br>
              <span v-if="assignmentPlan.dailyEntries[day.toUpperCase()].hourEntries[interval].numChildrenKrippe > assignmentPlan.plaetzeU3" class="warning">&nbsp;<b>Warnung:</b> Nur {{assignmentPlan.plaetzeU3}} <i>Plätze unter 3 Jahre</i> vorhanden<br></span>
              <b>{{assignmentPlan.dailyEntries[day.toUpperCase()].hourEntries[interval].numChildrenKindergarten}}</b> Kindergarten-Kind<span v-if="assignmentPlan.dailyEntries[day.toUpperCase()].hourEntries[interval].numChildrenKindergarten !== 1">er</span><br>
              <span v-if="assignmentPlan.dailyEntries[day.toUpperCase()].hourEntries[interval].numChildrenKindergarten > assignmentPlan.plaetze3bisEinschulung" class="warning">&nbsp;<b>Warnung:</b> Nur {{assignmentPlan.plaetze3bisEinschulung}} <i>Plätze ab 3 Jahre bis Einschulung</i> vorhanden<br></span>
              <b>{{assignmentPlan.dailyEntries[day.toUpperCase()].hourEntries[interval].numChildrenHort}}</b> Hort-Kind<span v-if="assignmentPlan.dailyEntries[day.toUpperCase()].hourEntries[interval].numChildrenHort !== 1">er</span><br>
              <span v-if="assignmentPlan.dailyEntries[day.toUpperCase()].hourEntries[interval].numChildrenHort > assignmentPlan.plaetzeSchulkinder" class="warning">&nbsp;<b>Warnung:</b> Nur {{assignmentPlan.plaetzeSchulkinder}} <i>Plätze Schulkinder</i> vorhanden<br></span>
              <br><b>{{assignmentPlan.dailyEntries[day.toUpperCase()].hourEntries[interval].numChildren}}</b> Kind<span v-if="assignmentPlan.dailyEntries[day.toUpperCase()].hourEntries[interval].numChildren !== 1">er</span> gesamt<br>
              <i v-for="(child, index) of assignmentPlan.dailyEntries[day.toUpperCase()].hourEntries[interval].childInfos" :key="child.id" v-if="index < 3">{{child.fullName}}<span v-if="index < 2">,&nbsp;</span><span v-else>&nbsp;...</span></i>
            </md-tooltip>

          </md-card>

        </td>
      </tr>
    </table>
  </div>
</template>

<script>
  import Vue from 'vue';
  import StickyMenu from "../menus/StickyMenu";
  import Spinner from 'vue-simple-spinner';

  export default {
    name: 'AssignmentPlanView',
    components: {
      StickyMenu,
      'vue-simple-spinner': Spinner,
    },
    props: {
      assignmentPlan: {
        required: true
      },
      facility: {
        required: true
      },
      sending: {
        required: true
      },
      year: {
        required: true
      },
      month: {
        required: true
      },
      selectedDivisions: {
        required: true
      },
      selectedDay: {
        required: true
      },
      selectedInterval: {
        required: true
      },
    },

    mounted() {
      this.initData();
    },

    watch: {
      facility() {
        this.initData();
      },
    },

    data() {
      return {
        hoursIntervals: [],
        startIntervals: 0,
        endIntervals: 0,

        showKrippe: true,
        showKINDERGARTEN: true,
        showHort: true,

        showAllDays: false,
      }
    },

    methods: {
      initData() {
        if (this.facility && this.facility.facilitySettings) {
          this.hoursIntervals = [];
          this.startIntervals = Number(this.facility.facilitySettings.bookingTimesStart.substring(0, 2));
          this.endIntervals = Number(this.facility.facilitySettings.bookingTimesEnd.substring(0, 2));
          for (let i = this.startIntervals; i < this.endIntervals; i++) {
            this.hoursIntervals.push(i + '-' + (i + 1));
          }
        }
      },

      getMonthStr(month) {
        return Vue.moment(month + '-01-' + this.selectedYear, 'MM-DD-YYYY').format('MMMM');
      },
      
      onPreviousMonth() {
        this.$emit("previousMonth");
      },

      onNextMonth() {
        this.$emit("nextMonth");
      },

      getStyle(day, interval, type) {

        let maxHeight = 22;
        let numChildren = this.assignmentPlan.dailyEntries[day.toUpperCase()].numChildren;

        if (interval !== '') {
          let krippeHeight = Math.floor((this.assignmentPlan.dailyEntries[day.toUpperCase()].hourEntries[interval].numChildrenKrippe / numChildren) * maxHeight);
          let KindergartenHeight = Math.floor((this.assignmentPlan.dailyEntries[day.toUpperCase()].hourEntries[interval].numChildrenKindergarten / numChildren) * maxHeight);
          let hortHeight = Math.floor((this.assignmentPlan.dailyEntries[day.toUpperCase()].hourEntries[interval].numChildrenHort / numChildren) * maxHeight);

          if (type === 'KRIPPE') {
            return 'background: lightpink;vertical-align: bottom; height: ' + krippeHeight + 'px;';
          }
          if (type === 'KINDERGARTEN') {
            return 'background: lightskyblue;vertical-align: bottom; height: ' + KindergartenHeight + 'px;';
          }
          if (type === 'HORT') {
            return 'background: lightgreen;vertical-align: bottom; height: ' + hortHeight + 'px;';
          }
        }

        if (type === 'TOTAL') {
          return 'background: #009688;vertical-align: bottom; height: ' + maxHeight + 'px;';
        }

        if (type === 'RED' && numChildren > this.assignmentPlan.plaetzeLautBetriebserlaubnis) {
          let height =  Math.floor(((numChildren - this.assignmentPlan.plaetzeLautBetriebserlaubnis) / numChildren) * maxHeight);
          return 'background: red;vertical-align: bottom;border-radius: 2px;margin-top: -3px;width: 100%; height: ' + height + 'px;';
        }

        if (type === 'ORANGE' && numChildren > this.assignmentPlan.maxAllowedChildren) {
          let height =  Math.floor(((numChildren - this.assignmentPlan.maxAllowedChildren) / numChildren) * maxHeight);
          return 'background: orange;vertical-align: bottom;border-radius: 2px;margin-top: -3px;width: 50%; height: ' + height + 'px;';
        }

        return '';
      },

      selectDailyEntry(entry, day, interval) {
        if (day === this.selectedDay && interval === this.selectedInterval) {
          this.$emit("updateChildren", [], null, null);
        } else {
          this.$emit("updateChildren", entry.childInfos, day, interval);
        }
      },

      getSelectedEntryStyle(day, interval) {
        if (day === this.selectedDay && interval === this.selectedInterval) {
          return 'background: #faebd780;';
        }

        return '';
      },
    },

    computed: {
      organization() {
        return this.$store.getters.organization;
      },
    }
  }
</script>

<style lang="scss" scoped>

  p {
    margin: 0;
  }

  .color-span {
    color: cornflowerblue;
  }

  table {
    border-collapse: separate;
    empty-cells: hide;
    width: 100% !important;
    margin: 0;
    border-spacing: 1px;
  }

  th {
    color: grey;
    padding: 0;
    font-size: large;
    font-weight: lighter;
    width: 12.5%;
  }

  th:hover {
    cursor: pointer;
  }

  hr {
    margin-top: 3px;
    margin-bottom: 0px;
  }

  .md-card-content {
    padding-top: 20px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .md-card-header {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
  }

  .md-card .md-title {
    font-size: 18px;
    font-weight: 100;
  }

  .md-card .md-subhead {
    font-size: 12px;
    text-align: center;
    position: relative;
    margin-top: -17px;
  }

  .md-card-load {
    width: 33%;
    display: inline-block;
  }

  .md-card-total-load {
    width: 80%;
    display: inline-block;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    max-width: 500px;
    white-space: pre-wrap;
  }

  .warning {
    color: orangered;
  }

  @import "../../../node_modules/vue-material/src/theme/engine";

  .md-tooltip {
    @include md-theme-component() {
      @include md-theme-property(color, text-primary, tooltip);
      @include md-theme-property(background-color, tooltip, background, .9);
    }
  }

  .md-theme-default th .md-card {
    background: whitesmoke;
    color: black;
  }

  .md-theme-dark th .md-card  {
    background: #666666;
    color: antiquewhite;
  }

  .md-theme-default td .md-card  {
    background: white;
    color: black;
  }

  .md-theme-dark td .md-card,  {
    background: #424242;
    color: antiquewhite;
  }

</style>

<style>
  .md-ripple {
    z-index: 4 !important;
  }
</style>
