<template>
  <div v-if="facility">
    <div class="grid-x">
      <div class="cell large-5 medium-5 small-12">
        <div class="md-title" style="font-size: x-large; margin: 0;">
          {{facility.name}}
        </div>
      </div>
      <div class="cell large-6 medium-6 small-12 text-center">


        <div class="md-title" style="font-size: large; margin-top: 5px; margin-bottom: 0;">
          Stand: <b style="color: seagreen; font-size: x-large;">{{ today | moment("dddd, DD MMMM YYYY")}}</b>
        </div>
<!--
        <md-button class="md-icon-button md-dense md-primary" @click="onPreviousYear">
          <md-icon>chevron_left</md-icon>
          <md-tooltip>vorheriges Jahr</md-tooltip>
        </md-button>
        <span class="md-title"
              style="font-size: x-large; color: cornflowerblue; width: 210px;display: inline-block;text-align: center;">&nbsp;<b>{{year}}</b>&nbsp;</span>
        <md-button class="md-icon-button md-dense md-primary" @click="onNextYear">
          <md-icon>chevron_right</md-icon>
          <md-tooltip>nächstes Jahr</md-tooltip>
        </md-button>
-->
      </div>
      <div class="cell large-1 medium-1 hide-for-small-only">
        <vue-simple-spinner v-if="sending > 0"></vue-simple-spinner>
      </div>

      <div class="cell large-6" style="margin-top: 10px; ">
        <md-card class="md-card-flat">
          <md-card-expand :style="simulationStyle">
            <md-card-actions md-alignment="left">
              <md-card-expand-trigger>
                <md-button id="trigger-children-chart" class="md-icon-button">
                  <md-icon>keyboard_arrow_down</md-icon>
                  <md-tooltip>Zeige Kinder-Übersicht</md-tooltip>
                </md-button>
              </md-card-expand-trigger>
              <div v-if="statistic">
                <label style="font-size: large">&nbsp;&nbsp; <b v-if="statistic.kibigMonthlyData[today.month()].sum > 0" style="color: cornflowerblue;font-size: x-large">{{extRoundDe(statistic.kibigMonthlyData[today.month()].sum)}}</b><span v-else>Keine</span> Kind<span v-if="statistic.kibigMonthlyData[today.month()].sum !== 1">er</span> mit Buchungszeiten
                  <span v-if="simulationActive" style="font-size: large;">&nbsp;&nbsp;&nbsp;<b class="flash-orange-red"><i>Simulation aktiv</i></b>
                    <md-tooltip>Es werden aktuell simulierte Daten angezeigt.</md-tooltip>
                  </span>
                </label>
              </div>
            </md-card-actions>
            <md-card-expand-content>
              <md-card-content style="padding-bottom: 8px; 'background: ff450014'">
                <DoughnutChart v-if="childrenDoughnutChartData && childrenDoughnutChartOptions"
                               :chartData="childrenDoughnutChartData" style="max-height: 200px"
                               :options="childrenDoughnutChartOptions"></DoughnutChart>
              </md-card-content>
            </md-card-expand-content>
          </md-card-expand>
        </md-card>
      </div>
      <div class="cell large-6" style="margin-top: 10px;">
        <md-card class="md-card-flat">
          <md-card-expand :style="simulationStyle">
            <md-card-actions md-alignment="left">
              <md-card-expand-trigger>
                <md-button id="trigger-facility-line-chart" class="md-icon-button">
                  <md-icon>keyboard_arrow_down</md-icon>
                  <md-tooltip>Zeige Anstellungsschlüssel-Verlauf</md-tooltip>
                </md-button>
              </md-card-expand-trigger>
              <div v-if="statistic">
                <label style="font-size: large">&nbsp;&nbsp;Anstellungsschlüssel-Verlauf
                  <span v-if="simulationActive" style="font-size: large;">&nbsp;&nbsp;&nbsp;<b class="flash-orange-red"><i>Simulation aktiv</i></b>
                    <md-tooltip>Es werden aktuell simulierte Daten angezeigt.</md-tooltip>
                  </span>
                </label>
              </div>
            </md-card-actions>
            <md-card-expand-content>
              <md-card-content style="padding-bottom: 8px;">
                <LineChart v-if="facilityLineChartData && facilityLineChartOptions"
                               :chartData="facilityLineChartData" style="max-height: 206px"
                               :options="facilityLineChartOptions"></LineChart>
              </md-card-content>
            </md-card-expand-content>
          </md-card-expand>
        </md-card>
      </div>

      <div v-if="overview && !simulationActive" class="cell large-6" style="margin-top: 15px;">
        <md-card class="md-card-flat">
          <md-card-expand>
          <md-card-actions md-alignment="left">
            <md-card-expand-trigger>
              <md-button class="md-icon-button">
                <md-icon>keyboard_arrow_down</md-icon>
                <md-tooltip>Zeige Geburtage</md-tooltip>
              </md-button>
            </md-card-expand-trigger>
            <div>
              <label style="font-size: large">&nbsp;&nbsp; <b v-if="overview.childsBirthday.length > 0" style="color: deeppink;font-size: x-large">{{overview.childsBirthday.length}}</b><span v-else>Keine</span> Kinder-Geburtstag<span v-if="overview.childsBirthday.length !== 1">e</span>
                <span v-if="overview.childsBirthday.length > 0">&nbsp;🎂</span>
                <span v-else>&nbsp;<md-icon style="color: green; margin-bottom: 6px;">check</md-icon></span>
              </label>
              <md-tooltip v-if="overview.childsBirthday.length > 0" md-direction="bottom">{{overview.childsBirthday.length}} Kind<span v-if="overview.childsBirthday.length !== 1">er</span>&nbsp;<span v-if="overview.childsBirthday.length !== 1">haben</span><span v-else>hat</span> bald Geburtstag!</md-tooltip>
              <md-tooltip v-else md-direction="bottom">Keine demnächst anstehenden Kinder-Geburtstage.</md-tooltip>
            </div>
          </md-card-actions>

          <md-card-expand-content>
            <md-card-content style="padding-bottom: 8px;">

              <md-table v-model="searchedChildren" md-sort="fullName" md-sort-order="desc"
                        md-card md-fixed-header style="height: 399px">
                <md-table-toolbar :style="toolBarStyleChildren">
                  <div class="md-toolbar-section-start grid-x grid-padding-x">
                    <div class="cell large-6 hide-for-medium-only hide-for-small-only" style="margin-top: 16px;margin-bottom: 16px;">
                      <p style="color: deeppink;">Anstehende Geburtstage</p>
                      <p class="md-title ai-number">
                        <span style="font-size: 2rem">🎂</span>
                        &nbsp;{{searchedChildren.length}} Kind<span v-if="searchedChildren.length !== 1">er</span></p>
                    </div>
                    <div class="cell large-6 medium-12 small-12">
                      <md-field md-clearable class="md-toolbar-section-end">
                        <md-input placeholder="Suche Name ..." v-model="searchChild"
                                  @input="searchOnChildTable"/>
                      </md-field>
                    </div>
                  </div>
                </md-table-toolbar>

                <md-table-empty-state v-if="searchChild"
                                      md-label="Kein Kind gefunden"
                                      :md-description="`Kein Kind mit dem Suchbegriff '${searchChild}' gefunden. Versuchen Sie es mit einem anderen Begriff.`">
                </md-table-empty-state>

                <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)">
                  <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Name"
                                 md-sort-by="fullName">
                    <ImageView :imageId="item.imageId" size="micro"></ImageView>&nbsp;&nbsp;&nbsp;&nbsp;
                    <router-link :to="openChildLink(item.id)"><span>{{item.fullName}}</span>
                      <md-tooltip md-direction="bottom" style="font-size: medium;">Daten öffnen für <b>{{item.fullName}}</b> ({{labels.divisiontype[item.divisiontype]}})</md-tooltip>
                    </router-link>
                  </md-table-cell>
                  <md-table-cell md-label="Geburtstag" md-sort-by="birthdate">{{ item.birthdate | moment("DD.MM.YYYY") }}
                  </md-table-cell>
                  <md-table-cell md-label="Alter" md-sort-by="ageTextual">{{ item.ageTextual }}
                  </md-table-cell>
                </md-table-row>
              </md-table>

            </md-card-content>
          </md-card-expand-content>
        </md-card-expand>
        </md-card>
      </div>
      <div v-if="overview && !simulationActive" class="cell large-6" style="margin-top: 15px;">
        <md-card class="md-card-flat">
        <md-card-expand>
          <md-card-actions md-alignment="left">
            <md-card-expand-trigger>
              <md-button class="md-icon-button">
                <md-icon>keyboard_arrow_down</md-icon>
                <md-tooltip>Zeige Geburtage</md-tooltip>
              </md-button>
            </md-card-expand-trigger>
            <div>
              <label style="font-size: large">&nbsp;&nbsp; <b  v-if="overview.employeesBirthday.length > 0" style="color: deeppink;font-size: x-large">{{overview.employeesBirthday.length}}</b><span v-else>Keine</span> Mitarbeiter-Geburtstag<span v-if="overview.employeesBirthday.length !== 1">e</span>
                <span v-if="overview.employeesBirthday.length > 0">&nbsp;🎉</span>
                <span v-else>&nbsp;<md-icon style="color: green; margin-bottom: 6px;">check</md-icon></span>
              </label>
              <md-tooltip v-if="overview.employeesBirthday.length > 0" md-direction="bottom">{{overview.employeesBirthday.length}} Mitarbeiter&nbsp;<span v-if="overview.employeesBirthday.length !== 1">haben</span><span v-else>hat</span> bald Geburtstag!</md-tooltip>
              <md-tooltip v-else md-direction="bottom">Keine demnächst anstehenden Mitarbeiter-Geburtstage.</md-tooltip>
            </div>
          </md-card-actions>

          <md-card-expand-content>
            <md-card-content style="padding-bottom: 8px;">

              <md-table v-model="searchedEmployees" md-sort="fullName" md-sort-order="desc"
                        md-card md-fixed-header style="height: 399px">
                <md-table-toolbar :style="toolBarStyleEmployees">
                  <div class="md-toolbar-section-start grid-x grid-padding-x">
                    <div class="cell large-6 hide-for-medium-only hide-for-small-only" style="margin-top: 16px;margin-bottom: 16px;">
                      <p style="color: deeppink;">Anstehende Geburtstage</p>
                      <p class="md-title ai-number">
                        <span style="font-size: 2rem">🎉</span>
                        &nbsp;&nbsp;&nbsp;{{searchedEmployees.length}} Mitarbeiter</p>
                    </div>
                    <div class="cell large-6 medium-12 small-12">
                      <md-field md-clearable class="md-toolbar-section-end">
                        <md-input placeholder="Suche Name ..." v-model="searchEmployee"
                                  @input="searchOnEmployeeTable"/>
                      </md-field>
                    </div>
                  </div>
                </md-table-toolbar>

                <md-table-empty-state v-if="searchEmployee"
                                      md-label="Keinen Mitarbeiter gefunden"
                                      :md-description="`Keinen Mitarbeiter mit dem Suchbegriff '${searchEmployee}' gefunden. Versuchen Sie es mit einem anderen Begriff.`">
                </md-table-empty-state>

                <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)">
                  <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Name"
                                 md-sort-by="fullName">
                    <ImageView :imageId="item.imageId" size="micro"></ImageView>&nbsp;&nbsp;&nbsp;&nbsp;
                    <router-link :to="openEmployeeLink(item.id)"><span>{{item.fullName}}</span>
                      <md-tooltip md-direction="bottom" style="font-size: medium;">Daten öffnen für <b>{{item.fullName}}</b> ({{labels.divisiontype[item.divisiontype]}})</md-tooltip>
                    </router-link>
                  </md-table-cell>
                  <md-table-cell md-label="Geburtstag" md-sort-by="birthdate">{{ item.birthdate | moment("DD.MM.YYYY") }}
                  </md-table-cell>
                  <md-table-cell md-label="Alter" md-sort-by="ageTextual">{{ item.ageTextual }}
                  </md-table-cell>
                </md-table-row>
              </md-table>

            </md-card-content>
          </md-card-expand-content>
        </md-card-expand>
        </md-card>
      </div>

      <div v-if="overview && !simulationActive" class="cell large-6" style="margin-top: 15px;">
        <md-card class="md-card-flat">
        <md-card-expand>
          <md-card-actions md-alignment="left">
            <md-card-expand-trigger>
              <md-button class="md-icon-button">
                <md-icon>keyboard_arrow_down</md-icon>
                <md-tooltip>Zeige Alarme</md-tooltip>
              </md-button>
            </md-card-expand-trigger>
            <div>
              <label style="font-size: large">&nbsp;&nbsp; <b v-if="searchedChildrenRedAlerts.length > 0" style="color: red;font-size: x-large">{{searchedChildrenRedAlerts.length}}</b><span v-else>Keine</span> Kinder-Alarm<span v-if="searchedChildrenRedAlerts.length !== 1">e</span>
                <span v-if="searchedChildrenRedAlerts.length > 0">&nbsp;<md-icon style="color: red; margin-bottom: 6px;">error_outline</md-icon></span>
                <span v-else>&nbsp;<md-icon style="color: green; margin-bottom: 6px;">check</md-icon></span>
              </label>
              <md-tooltip v-if="searchedChildrenRedAlerts.length > 0" md-direction="bottom">Bei&nbsp;{{searchedChildrenRedAlerts.length}}&nbsp;Kind<span v-if="searchedChildrenRedAlerts.length !== 1">ern</span>&nbsp;gibt es rote Alarme!</md-tooltip>
              <md-tooltip v-else md-direction="bottom">Aktuell gibt es keine Alarme bei Kindern.</md-tooltip>
            </div>
          </md-card-actions>

          <md-card-expand-content>
            <md-card-content style="padding-bottom: 8px;">

              <md-table v-model="searchedChildrenRedAlerts" md-sort="fullName" md-sort-order="desc"
                        md-card md-fixed-header style="height: 399px">
                <md-table-toolbar :style="toolBarStyleChildren">
                  <div class="md-toolbar-section-start grid-x grid-padding-x">
                    <div class="cell large-6 hide-for-medium-only hide-for-small-only" style="margin-top: 16px;margin-bottom: 16px;">
                      <p style="color: red;">Rote Alarme bei Kindern</p>
                      <p class="md-title ai-number">
                        <md-icon v-if="searchedChildrenRedAlerts.length > 0" style="color: red; margin-bottom: 6px;">error_outline</md-icon>
                        &nbsp;{{searchedChildrenRedAlerts.length}} Kind<span v-if="searchedChildrenRedAlerts.length !== 1">er</span></p>
                    </div>
                    <div class="cell large-6 medium-12 small-12">
                      <md-field md-clearable class="md-toolbar-section-end">
                        <md-input placeholder="Suche Name ..." v-model="searchChildRedAlert"
                                  @input="searchOnChildAlertsTable"/>
                      </md-field>
                    </div>
                  </div>
                </md-table-toolbar>

                <md-table-empty-state v-if="searchChildRedAlert"
                                      md-label="Kein Kind gefunden"
                                      :md-description="`Kein Kind mit dem Suchbegriff '${searchChildRedAlert}' gefunden. Versuchen Sie es mit einem anderen Begriff.`">
                </md-table-empty-state>

                <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)">
                  <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Name"
                                 md-sort-by="fullName">
                    <ImageView :imageId="item.imageId" size="micro"></ImageView>&nbsp;&nbsp;&nbsp;&nbsp;
                    <router-link :to="openChildLink(item.id)"><span>{{item.fullName}}</span>
                      <md-tooltip md-direction="bottom" style="font-size: medium;">Daten öffnen für <b>{{item.fullName}}</b> ({{labels.divisiontype[item.divisiontype]}})</md-tooltip>
                    </router-link>
                  </md-table-cell>
                  <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Alarme" md-sort-by="urgency">
                    <AlertIcons :alerts="item.alerts" index="-1" @updateAlertUrgency="item.urgency = arguments[0]"></AlertIcons>
                  </md-table-cell>
                </md-table-row>
              </md-table>

            </md-card-content>
          </md-card-expand-content>
        </md-card-expand>
        </md-card>
      </div>
      <div v-if="overview && !simulationActive" class="cell large-6" style="margin-top: 15px;">
        <md-card class="md-card-flat">
        <md-card-expand>
          <md-card-actions md-alignment="left">
            <md-card-expand-trigger>
              <md-button class="md-icon-button">
                <md-icon>keyboard_arrow_down</md-icon>
                <md-tooltip>Zeige Alarme</md-tooltip>
              </md-button>
            </md-card-expand-trigger>
            <div>
              <label style="font-size: large">&nbsp;&nbsp; <b v-if="searchedEmployeesRedAlerts.length > 0" style="color: red;font-size: x-large">{{searchedEmployeesRedAlerts.length}}</b><span v-else>Keine</span>&nbsp;Mitarbeiter-Alarm<span v-if="searchedEmployeesRedAlerts.length !== 1">e</span>
                <span v-if="searchedEmployeesRedAlerts.length > 0">&nbsp;<md-icon style="color: red; margin-bottom: 6px;">error_outline</md-icon></span>
                <span v-else>&nbsp;<md-icon style="color: green; margin-bottom: 6px;">done</md-icon></span>
              </label>
              <md-tooltip v-if="searchedEmployeesRedAlerts.length > 0" md-direction="bottom">Bei&nbsp;{{searchedEmployeesRedAlerts.length}}&nbsp;Mitarbeiter<span v-if="searchedEmployeesRedAlerts.length !== 1">n</span>&nbsp;gibt es rote Alarme!</md-tooltip>
              <md-tooltip v-else md-direction="bottom">Aktuell gibt es keine Alarme bei Mitarbeitern.</md-tooltip>
            </div>
          </md-card-actions>

          <md-card-expand-content>
            <md-card-content style="padding-bottom: 8px;">

              <md-table v-model="searchedEmployeesRedAlerts" md-sort="fullName" md-sort-order="desc"
                        md-card md-fixed-header style="height: 399px">
                <md-table-toolbar :style="toolBarStyleChildren">
                  <div class="md-toolbar-section-start grid-x grid-padding-x">
                    <div class="cell large-6 hide-for-medium-only hide-for-small-only" style="margin-top: 16px;margin-bottom: 16px;">
                      <p style="color: red;">Rote Alarme bei Mitarbeitern</p>
                      <p class="md-title ai-number">
                        <md-icon v-if="searchedEmployeesRedAlerts.length > 0" style="color: red; margin-bottom: 6px;">error_outline</md-icon>
                        &nbsp;{{searchedEmployeesRedAlerts.length}} Mitarbeiter</p>
                    </div>
                    <div class="cell large-6 medium-12 small-12">
                      <md-field md-clearable class="md-toolbar-section-end">
                        <md-input placeholder="Suche Name ..." v-model="searchEmployeeRedAlert"
                                  @input="searchOnEmployeeAlertsTable"/>
                      </md-field>
                    </div>
                  </div>
                </md-table-toolbar>

                <md-table-empty-state v-if="searchEmployeeRedAlert"
                                      md-label="Keinen Mitarbeiter gefunden"
                                      :md-description="`Keinen Mitarbeiter mit dem Suchbegriff '${searchEmployeeRedAlert}' gefunden. Versuchen Sie es mit einem anderen Begriff.`">
                </md-table-empty-state>

                <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)">
                  <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Name"
                                 md-sort-by="fullName">
                    <ImageView :imageId="item.imageId" size="micro"></ImageView>&nbsp;&nbsp;&nbsp;&nbsp;
                    <router-link :to="openEmployeeLink(item.id)"><span>{{item.fullName}}</span>
                      <md-tooltip md-direction="bottom" style="font-size: medium;">Daten öffnen für <b>{{item.fullName}}</b> ({{labels.divisiontype[item.divisiontype]}})</md-tooltip>
                    </router-link>
                  </md-table-cell>
                  <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Alarme" md-sort-by="urgency">
                    <AlertIcons :alerts="item.alerts" index="-1" @updateAlertUrgency="item.urgency = arguments[0]"></AlertIcons>
                  </md-table-cell>
                </md-table-row>
              </md-table>

            </md-card-content>
          </md-card-expand-content>
        </md-card-expand>
        </md-card>
      </div>

    </div>
    <br>
  </div>
</template>

<script>
  import jQuery from 'jquery';
  import Spinner from 'vue-simple-spinner';
  import ImageView from './ImageView';
  import AlertIcons from '../animations/AlertIcons';
  import DoughnutChart from '../charts/DoughnutChart';
  import LineChart from '../charts/LineChart';
  import ChartColors from '../../components/charts/ChartColors';
  import Vue from 'vue';

  const toLower = text => {
    return text.toString().toLowerCase();
  };

  const searchByFullName = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.fullName).includes(toLower(term)));
    }

    return items;
  };

  export default {
    name: 'FacilityOverviewView',
    components: {
      'vue-simple-spinner': Spinner,
      ImageView,
      AlertIcons,
      DoughnutChart,
      LineChart,
      ChartColors,
    },
    props: {
      overviews: {
        required: true
      },
      statistic: {
        required: true
      },
      facility: {
        required: true
      },
      sending: {
        required: true
      },
      year: {
        required: true
      },
      simulationActive: {
        required: true
      },
    },

    watch: {
      overviews() {
        this.determineOverview();
        this.searchOnChildTable();
        this.searchOnEmployeeTable();
        this.searchOnChildAlertsTable();
        this.searchOnEmployeeAlertsTable();
        if (this.overview && this.overview.kibigCalculatedData && this.overview.kibigCalculatedData.length > 0) {
          this.buildFacilityChart(this.overview.kibigCalculatedData[0].kibigMonthlyData);
        }
      },

      statistic() {
        this.buildChildrenChart();
        if (this.simulationActive) {
          if (this.statistic && this.statistic.kibigMonthlyDataSeq) {
            this.buildFacilityChart(this.statistic.kibigMonthlyDataSeq);
          }
        } else {
          if (this.overview && this.overview.kibigCalculatedData && this.overview.kibigCalculatedData.length > 0) {
            this.buildFacilityChart(this.overview.kibigCalculatedData[0].kibigMonthlyData);
          }
        }
      },
    },

    data() {
      return {
        today: Vue.moment(),

        searchedChildren: [],
        searchChild: null,
        searchedEmployees: [],
        searchEmployee: null,
        searchedChildrenRedAlerts: [],
        searchChildRedAlert: null,
        searchedEmployeesRedAlerts: [],
        searchEmployeeRedAlert: null,
        overview: null,

        childrenChartExpanded: false,
        childrenDoughnutChartData: null,
        childrenDoughnutChartOptions: {

          circumference: Math.PI,
          rotation: - Math.PI,

          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: 'top',
          },
          layout: {
            padding: {
              top: 0,
              bottom: 0,
            }
          },
        },

        facilityLineChartExpanded: false,
        facilityLineChartData: null,
        facilityLineChartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: 'top',
          },
          layout: {
            padding: {
              top: 0,
              bottom: 0,
            }
          },
        },
      }
    },

    methods: {
      onPreviousYear() {
        this.$emit("previousYear");
      },

      onNextYear() {
        this.$emit("nextYear");
      },

      toolBarStyleChildren() {
        return this.searchedChildren.length > 0 ? 'background: antiquewhite' : '';
      },

      toolBarStyleEmployees() {
        return this.searchedEmployees.length > 0 ? 'background: antiquewhite' : '';
      },

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      openChildLink(id) {
        return '/Kinder/'+ id;
      },

      openEmployeeLink(id) {
        return '/Verwaltung/Mitarbeiter/'+ id;
      },

      determineOverview() {
        if (this.overviews && this.facility) {
          for (let overview of this.overviews) {
            if (overview.facRef === this.facility.referenceNumber) {
              this.overview = overview;
            }
          }
        }
      },

      searchOnChildTable() {
        if (this.overview && this.overview.childsBirthday) {
          this.searchedChildren = searchByFullName(this.overview.childsBirthday, this.searchChild);
        }
      },

      searchOnEmployeeTable() {
        if (this.overview && this.overview.employeesBirthday) {
          this.searchedEmployees = searchByFullName(this.overview.employeesBirthday, this.searchEmployee);
        }
      },

      searchOnChildAlertsTable() {
        if (this.overview && this.overview.childsRedAlerts) {
          this.searchedChildrenRedAlerts = searchByFullName(this.overview.childsRedAlerts, this.searchChildRedAlert);
        }
      },

      searchOnEmployeeAlertsTable() {
        if (this.overview && this.overview.employeesRedAlerts) {
          this.searchedEmployeesRedAlerts = searchByFullName(this.overview.employeesRedAlerts, this.searchEmployeeRedAlert);
        }
      },

      extRound(num) {
        num = (Math.round(num * 10) / 10);
        return num;
      },

      extRoundDe(num) {
        num = (Math.round(num * 10) / 10);
        return String(num).replace('.', ',');
      },

      buildFacilityChart(kibigMonthlyData) {
        let labels = [];
        let dataMin = [];
        let data = [];

        for (let item of kibigMonthlyData) {
          labels.push(this.$material.locale.shortMonths[item.month - 1] + ' ' + String(item.year).substr(2,2));
          data.push(this.extRound(item.anstellungsschluessel));
          dataMin.push(this.extRound(11));
        }

        this.facilityLineChartData = {
          datasets: [{
            label: 'Anstellungsschlüssel',
            borderColor: ChartColors.getColor4Type('Anstellungsschlüssel'),
            backgroundColor: ChartColors.getColor4Type('Anstellungsschlüssel'),
            data: data,
            fill: false,
            lineTension: 0,
          }, {
            label: 'Mindestanforderung',
            borderColor: ChartColors.getColor4Type('Mindestanforderung'),
            backgroundColor: ChartColors.getColor4Type('Mindestanforderung'),
            data: dataMin,
            fill: false,
            lineTension: 0,
          }],
          labels: labels,
        };

        if (!this.facilityLineChartExpanded) {
          jQuery('#trigger-facility-line-chart').trigger('click');
          this.facilityLineChartExpanded = true;
        }
      },

      buildChildrenChart() {
        if (this.statistic) {

          let month = this.today.month();

          let data = [];
          let labels = [];
          let colors = [];

          if (this.statistic.kibigMonthlyData[month].numRegelkind > 0) {
            data.push(this.extRound(this.statistic.kibigMonthlyData[month].numRegelkind));
            labels.push('Regelkinder');colors.push(ChartColors.getColor4Type('Regelkinder'));
          }
          if (this.statistic.kibigMonthlyData[month].numSchulkind > 0) {
            data.push(this.extRound(this.statistic.kibigMonthlyData[month].numSchulkind));
            labels.push('Schulkinder');colors.push(ChartColors.getColor4Type('Schulkinder'));
          }
          if (this.statistic.kibigMonthlyData[month].numMigration > 0) {
            data.push(this.extRound(this.statistic.kibigMonthlyData[month].numMigration));
            labels.push('Migration');colors.push(ChartColors.getColor4Type('Migration'));
          }
          if (this.statistic.kibigMonthlyData[month].numU3kinder > 0) {
            data.push(this.extRound(this.statistic.kibigMonthlyData[month].numU3kinder));
            labels.push('U3 Kinder');colors.push(ChartColors.getColor4Type('U3Kinder'));
          }
          if (this.statistic.kibigMonthlyData[month].numBehinderung > 0) {
            data.push(this.extRound(this.statistic.kibigMonthlyData[month].numBehinderung));
            labels.push('Förderkinder');colors.push(ChartColors.getColor4Type('Behinderung'));
          }
          if (this.statistic.kibigMonthlyData[month].numVorschulkinderOrZuschussKinder > 0) {
            data.push(this.extRound(this.statistic.kibigMonthlyData[month].numVorschulkinderOrZuschussKinder));
            labels.push('Vorschul-/Zuschusskinder');colors.push(ChartColors.getColor4Type('Vorschul-/Zuschusskinder'));
          }

          this.childrenDoughnutChartData = {
            datasets: [{
              data: data,
              backgroundColor: colors,
              borderWidth: 0,
            }],
            labels: labels,
          };

          if (!this.childrenChartExpanded) {
            jQuery('#trigger-children-chart').trigger('click')
            this.childrenChartExpanded = true;
          }
        }
      },
    },

    computed: {
      labels() {
        return this.$store.getters.labels
      },

      simulationStyle() {
        if (this.simulationActive) {
          // return 'background: #ff450014 !important;';
          return '';
        } else {
          return '';
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .md-toolbar.md-theme-default.md-transparent .md-title {
    color: cornflowerblue;
    margin-left: 0;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    max-width: 500px;
    white-space: pre-wrap;
  }
</style>

